<script>
import VueMarkdown from "vue-markdown";
import { Card, Button, Checkbox } from "@/components";
import Client from "@/services/Client";
import VLoader from "@/components/VLoader";

export default {
    name: "BlogPost",
    bodyClass: "blog-post",
    props: {
        lang: {},
        doc: {},
    },
    components: {
        Card,
        VLoader,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        VueMarkdown,
    },
    data() {
        return {
            loading: true,
            document: null,
        };
    },
    computed: {
        currentLang() {
            return this.$i18n.locale;
        }
    },
    watch: {
        currentLang: function (nV) {
            this.$router.push({name: 'doc', params: {lang: 'toc', doc: this.$i18n.locale}});
        }
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await Client.document(this.lang, this.doc);
                this.document = r.data.data;
            } catch (e) {
                this.$router.push({ name: "login" });
            }

            this.loading = false;

            this.$nextTick(() => {
                document.querySelectorAll(".marina-content a").forEach((a) => {
                    if (
                        "href" in a.attributes &&
                        (a.attributes.href.value.startsWith("/docs/") ||
                            a.attributes.href.value.startsWith("/docsgallery") ||
                            a.attributes.href.value.startsWith("/docscalendar"))
                    ) {
                        a.addEventListener("click", (e) => {
                            e.preventDefault();
                            this.$router.push({
                                path: a.attributes.href.value,
                            });
                        });
                    }
                });
            });
        },
    },
};
</script>

<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax
                class="page-header-image"
                style="background-image: url('/img/bgs/papug.jpg')"
            />
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">
                            <i v-if="loading" class="far fa-spinner"></i>
                            <template v-else>{{ document.toc.title }}</template>
                        </h2>
                        <h4 v-if="false">
                            WeChat launched in 2013. {{ lang }} / {{ doc }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <router-link
                                :to="{
                                    name: 'doc',
                                    params: { lang: 'toc', doc: $i18n.locale },
                                }"
                                class="btn btn-primary btn-round btn-lg"
                            >
                                <i class="now-ui-icons text_align-left" />
                                {{ $t("docs.back_to_table_of_contents") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pt-0">
                <div class="container">
                    <div class="row">
                        <div
                            class="col-md-12 ml-auto mr-auto"
                        >
                            <v-loader v-if="loading" />
                            <template v-else>

                                <vue-markdown class="marina-content">{{
                                    document.document
                                }}</vue-markdown>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>
