import axios from 'axios';

let instance = null;

if (!instance) {
    instance = axios.create({
        withCredentials: true,
        maxContentLength: 999999,
        timeout: 30000,
    });
}

// Interceptor for AUTH
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 401) {
        //console.log("Interceptor 401", error);
        //store.commit('auth/logoutUser');
        //router.push('/login');
        //window.location.href = '/login';
    }

    return Promise.reject(error);
})

export default instance;
