import Http from "@/services/Http";

export default {

    async login() {

    },

    async document(lang, slug, params = {}) {
        return Http.get(`/api/doc/${lang}/${slug}`, {params: params});
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(url, params = {}) {
        return Http.get(url, {params: params});
    },

    // async POST(data, params = {}) {
    //     return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data, {params: params});
    // },

}

